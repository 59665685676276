/* eslint-disable @backstage/no-relative-monorepo-imports */
/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Content, Header, InfoCard, Page } from '@backstage/core-components';

import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core';

import {
  APPROVER_EMAILS,
  DEPLOY_ROLE_FORM_DESCRIPTION,
  DEPLOY_ROLE_FORM_NAME,
} from '../../../../../packages/app/src/common/constants';

import { Account, State } from '../../../../../packages/app/src/store/types';

import {
  setApplicationAccounts,
  setGithubRepo,
} from '../../../../../packages/app/src/store/actions';
import { addNotifications } from '../../../../../packages/app/src/store/notifications';
import {
  DeployRoleCreationWorkflowRequest,
  Request,
  SDLC_STAGES,
  fetchAccountData,
  fetchGithubReposByOrg,
  triggerRequest,
} from '../../internal';
import { AsynchronousAutoComplete, BreadBoardAutoComplete } from '../common';
import { BreadBoardButtonGroup } from '../common/BreadBoardButtonGroup';

export const DeployRoleForm = () => {
  const dispatch = useDispatch();

  const accessToken: string = useSelector((state: State) => state.accessToken);
  const email = useSelector((state: State) => state.email);

  const applicationAccounts = useSelector(
    (state: State) => state.applicationAccounts,
  );
  const githubRepos = useSelector((state: State) => state.githubRepos);

  const [githubOrgValue, setGithubOrgValue] = useState<string>('');
  const [githubRepoDisabled, setGithubRepoDisabled] = useState<boolean>(true);
  const [approver, setApprover] = useState<string>('');
  const [repoName, setRepoName] = useState<string>('');
  const [deployRoleName, setDeployRoleName] = useState<string>('');
  const [envValue, setEnvValue] = useState<string>(SDLC_STAGES.NON_PROD);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [projectName, setProjectName] = useState<string>('');
  const [showProjectNamePrompt, setShowProjectNamePrompt] =
    useState<boolean>(false);
  const [selectedAccountDetail, setSelectedAccountDetail] = useState<Account>({
    accountEmail: '',
    accountId: '',
    accountName: '',
    accountOwner: '',
    accountType: '',
    accountState: '',
    accountStage: '',
    requestId: '',
  });

  const MAX_DEPLOY_ROLE_LENGTH = 64;

  const githubOrgOptions = {
    Option1: {
      value: 'adi-innersource',
      label: 'adi-innersource',
      isVisible: true,
    },
    Option2: {
      value: 'adi-partners',
      label: 'adi-partners',
      isVisible: true,
    },
  };

  const onGithubOrgChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGithubOrgValue(event.target.value);
    setGithubRepoDisabled(false);
  };

  useEffect(() => {
    if (accessToken) {
      fetchAccountData(accessToken).then(accountData => {
        dispatch(setApplicationAccounts(accountData.applicationAccounts));
      });
    }
  }, [dispatch, accessToken]);

  useEffect(() => {
    if (selectedAccountDetail.accountName.includes(SDLC_STAGES.PROD)) {
      setEnvValue(SDLC_STAGES.PROD);
    } else if (selectedAccountDetail.accountName.includes(SDLC_STAGES.DEV)) {
      setEnvValue(SDLC_STAGES.DEV);
    } else if (selectedAccountDetail.accountName.includes(SDLC_STAGES.QA)) {
      setEnvValue(SDLC_STAGES.QA);
    } else if (selectedAccountDetail.accountName.includes(SDLC_STAGES.STAGE)) {
      setEnvValue(SDLC_STAGES.STAGE);
    } else if (selectedAccountDetail.accountName.includes(SDLC_STAGES.UAT)) {
      setEnvValue(SDLC_STAGES.UAT);
    } else {
      setEnvValue(SDLC_STAGES.NON_PROD);
    }

    // Only set the deploy role name if a repository is selected
    if (repoName) {
      const baseRoleName = `spx-ops-${repoName
        .toLowerCase()
        .replace(/\s+/g, '-')}-deploy-${envValue.toLowerCase()}`;
      if (baseRoleName.length > MAX_DEPLOY_ROLE_LENGTH) {
        setShowProjectNamePrompt(true);
      } else {
        setShowProjectNamePrompt(false);
      }
      setDeployRoleName(baseRoleName);
    } else {
      setDeployRoleName('');
    }
  }, [envValue, repoName, selectedAccountDetail.accountName]);

  useEffect(() => {
    if (!showProjectNamePrompt) {
      setProjectName('');
    }
  }, [showProjectNamePrompt]);

  useEffect(() => {
    if (githubOrgValue && accessToken) {
      setIsLoading(true);
      fetchGithubReposByOrg(accessToken, githubOrgValue)
        .then(response => {
          dispatch(setGithubRepo(response));
          setGithubRepoDisabled(false);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  }, [githubOrgValue, accessToken, dispatch]);

  const handleProjectNameChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const newProjectName = event.target.value;

    // Enforce alphanumeric characters only
    const alphanumericRegex = /^[a-zA-Z0-9\-]*$/;
    if (alphanumericRegex.test(newProjectName)) {
      const baseRoleName = `spx-ops-${newProjectName
        .toLowerCase()
        .replace(/\s+/g, '-')}-deploy-${envValue.toLowerCase()}`;
      if (baseRoleName.length <= MAX_DEPLOY_ROLE_LENGTH) {
        setProjectName(newProjectName);
        setDeployRoleName(baseRoleName);
      }
    }
  };

  const handleReset = () => {
    setSelectedAccountDetail({
      accountEmail: '',
      accountId: '',
      accountName: '',
      accountOwner: '',
      accountType: '',
      accountState: '',
      accountStage: '',
      requestId: '',
    });
    setApprover('');
    setRepoName('');
    setProjectName('');
    setDeployRoleName('');
    setGithubOrgValue('');
    setGithubRepoDisabled(true);
    setIsLoading(false);
    setShowProjectNamePrompt(false);
  };

  const handleSubmit = async () => {
    setIsLoading(true);

    const requestMessage = `Request: Create a AWS-GitHub Deploy Role<br />
    Requester: ${email}<br />
    AWS Account Name: ${selectedAccountDetail.accountName}<br />
    GitHub Repository: ${repoName}<br />
    Deploy Role Name: ${deployRoleName}<br />
    `;

    const requestContent: DeployRoleCreationWorkflowRequest = {
      requester: email,
      projectName: projectName || repoName,
      accountId: selectedAccountDetail.accountId,
      repoName: repoName,
      githubOrganisation: githubOrgValue,
    };

    const request: Request = {
      requestType: DEPLOY_ROLE_FORM_NAME,
      description: requestMessage,
      approver: approver,
      requester: email,
      content: requestContent,
    };

    const response = await triggerRequest(request, accessToken);
    const requestId = response.data.requestId;

    addNotifications(
      DEPLOY_ROLE_FORM_NAME,
      `Request sent for approval to approver: '${approver}'.
      You can track the request '${requestId}' in 'Submissions' Tab in Self-Service.`,
      'info',
    );

    setIsLoading(false);

    handleReset();
  };

  const submitCheck = () => {
    const isInvalid =
      isLoading ||
      !selectedAccountDetail ||
      !repoName ||
      !approver ||
      deployRoleName.length > MAX_DEPLOY_ROLE_LENGTH;
    return isInvalid;
  };

  return (
    <Page themeId="tool">
      <Header
        title={DEPLOY_ROLE_FORM_NAME}
        subtitle={DEPLOY_ROLE_FORM_DESCRIPTION}
      />
      <Content>
        <Grid container spacing={3} direction="column">
          <Grid item xs={12} md={6}>
            <InfoCard>
              <FormControl variant="standard" fullWidth margin="dense">
                <FormLabel id="github-org-radio-button">
                  GitHub Organisation
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="github-org-radio-button"
                  name="radio-buttons-group"
                  onChange={onGithubOrgChange}
                  value={githubOrgValue}
                >
                  {githubOrgOptions.Option1.isVisible && (
                    <FormControlLabel
                      value={githubOrgOptions.Option1.value}
                      control={<Radio />}
                      label={githubOrgOptions.Option1.label}
                    />
                  )}
                  {githubOrgOptions.Option2.isVisible && (
                    <FormControlLabel
                      value={githubOrgOptions.Option2.value}
                      control={<Radio />}
                      label={githubOrgOptions.Option2.label}
                    />
                  )}
                </RadioGroup>
                <br />
                <AsynchronousAutoComplete
                  value={selectedAccountDetail}
                  options={applicationAccounts}
                  getOptionLabel={option => option.accountName}
                  groupBy={option => option.accountName[0]}
                  label="Account Name"
                  id="account-details"
                  onChange={(_event: any, newValue: any) =>
                    setSelectedAccountDetail(newValue)
                  }
                />
                <FormHelperText id="Account Name">
                  Select the AWS Account to create the role on
                </FormHelperText>
                <br />

                <AsynchronousAutoComplete
                  value={repoName}
                  options={githubRepos}
                  getOptionLabel={option => option}
                  groupBy={option => option[0]}
                  label="GitHub Repository"
                  id="repo-list"
                  onChange={(_event: any, newValue: any) =>
                    setRepoName(newValue)
                  }
                  isTextFieldDisable={githubRepoDisabled}
                />
                <FormHelperText id="RepoName">
                  Select a GitHub Repository from the list above to associate
                  the deploy role with
                </FormHelperText>
                <br />

                {showProjectNamePrompt && (
                  <>
                    <TextField
                      label="Project Name"
                      variant="outlined"
                      value={projectName}
                      onChange={handleProjectNameChange}
                      inputProps={{
                        maxLength: MAX_DEPLOY_ROLE_LENGTH,
                      }}
                      helperText={`The deploy role name cannot exceed ${MAX_DEPLOY_ROLE_LENGTH} characters. `}
                    />
                    <Typography variant="caption">
                      Remaining characters:{' '}
                      {MAX_DEPLOY_ROLE_LENGTH - deployRoleName.length}
                    </Typography>
                    <br />
                  </>
                )}

                <TextField
                  disabled
                  label="Deploy Role Name"
                  variant="outlined"
                  placeholder="Deploy Role Name"
                  value={deployRoleName}
                />
                <FormHelperText id="deploy-role-name-helper-text">
                  The deploy role name will be a lowercased and hyphenated
                  version of the GitHub repository name selected above, with the
                  following structure:
                  <br />
                  - Prefix: `spx-ops-`
                  <br />
                  - GitHub Repository Name or a custom project name (if
                  specified and valid)
                  <br />
                  - Postfix: `-deploy-[environment]`
                  <br />
                  E.g. for a repository named "My Repository" in the dev
                  environment, the role name will be
                  `spx-ops-my-repository-deploy-dev`.
                  <br />
                  If the generated name exceeds 64 characters, a custom project
                  name containing alphanumeric characters and hyphens will need
                  to be specified to reduce the length.
                </FormHelperText>
                <br />

                <BreadBoardAutoComplete
                  id="approver"
                  options={APPROVER_EMAILS}
                  value={approver}
                  onChange={(_event: any, newValue: any) =>
                    setApprover(newValue)
                  }
                  label="Approver's Email"
                />
                <FormHelperText id="approver-helper-text">
                  Please choose an approver who will authorise the creation of
                  the AWS/GitHub Deploy Role.
                </FormHelperText>
                <br />
              </FormControl>

              <BreadBoardButtonGroup
                handleSubmit={handleSubmit}
                handleReset={handleReset}
                submitCheck={submitCheck}
                isLoading={isLoading}
              />
            </InfoCard>
          </Grid>
        </Grid>
      </Content>
    </Page>
  );
};
